<template>
  <div class="main-wrapper">
    <div class="block-baner">
      <p>LE DROIT CIVIL</p>
    </div>
    <div class="main-section">
      <div class="padding-bottom-40">
        <p class="title padding-bottom-40">Le Droit civil</p>
        <p class="text padding-bottom-40">
          Le Cabinet offre ses expertises dans les domaines variés du droit
          civil aussi bien en conseil qu’en contentieux. Ses domaines
          d’expertises couvrent des domaines aussi variées que le contentieux
          foncier, le recouvrement des créances, la liquidation des successions.
        </p>
        <div class="block-menu margin-bottom-70">
          <div @click="$router.push('/PetroleumLaw')" class="list margin-right-60">
            <p class="text">Le Droit pétrolier</p>
          </div>
          <div
            @click="$router.push('/CitizenshipLaw')"
            class="list margin-right-60"
          >
            <p class="text">Le Droit de la Nationalité</p>
          </div>
          <div @click="$router.push('/IctLaw')" class="list">
            <p class="text">Le Droit des NTIC</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.main-section {
  max-width: 1112px;
  margin: 0 auto;
}
.title {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
}
.text {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.block-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list {
  height: 200px;
  width: 100%;
  background: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.block-baner {
  height: 102px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 92px;
  margin-bottom: 69px;
  p {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #ffffff;
  }
}
</style>
