import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/home'
import Skills from '@/components/skills'
import Contacts from '@/components/contacts'
import Consultation from '@/components/consultation'
import CitizenshipLaw from '@/components/citizenshipLaw'
import IctLaw from '@/components/ictLaw'
import CompetitionLaw from '@/components/competitionLaw'
import PetroleumLaw from '@/components/petroleumLaw'
import Transportlaw from '@/components/transportlaw'
import Administrativelaw from '@/components/administrativelaw'
import Civillaw from '@/components/civillaw'
import Taxlaw from '@/components/taxlaw'
import SocialLaw from '@/components/socialLaw'
import CompaniesLaw from '@/components/companiesLaw'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Skills',
    name: 'Skills',
    component: Skills
  },
  {
    path: '/Contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/Consultation',
    name: 'Consultation',
    component: Consultation
  },
  {
    path: '/CitizenshipLaw',
    name: 'CitizenshipLaw',
    component: CitizenshipLaw
  },
  {
    path: '/IctLaw',
    name: 'IctLaw',
    component: IctLaw
  },
  {
    path: '/CompetitionLaw',
    name: 'CompetitionLaw',
    component: CompetitionLaw
  },
  {
    path: '/PetroleumLaw',
    name: 'PetroleumLaw',
    component: PetroleumLaw
  },
  {
    path: '/Transportlaw',
    name: 'Transportlaw',
    component: Transportlaw
  },
  {
    path: '/Administrativelaw',
    name: 'Administrativelaw',
    component: Administrativelaw
  },
  {
    path: '/Civillaw',
    name: 'Civillaw',
    component: Civillaw
  },
  {
    path: '/Taxlaw',
    name: 'Taxlaw',
    component: Taxlaw
  },
  {
    path: '/SocialLaw',
    name: 'SocialLaw',
    component: SocialLaw
  },
  {
    path: '/CompaniesLaw',
    name: 'CompaniesLaw',
    component: CompaniesLaw
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
