<template>
  <div>
    <div class="fullscreen-bg">
      <div class="overlay">
        <div class="blur__block">
          <div class="blur__content">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  h1 {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 59px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    text-align: center;
    margin-bottom: 30px;
  }
  p {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    padding-bottom: 57px;
  }
  img {
      cursor: pointer;
  }
}
.to-bottom {
    text-align: center;
}
.blur__content {
  width: 50%;
}
.blur__img {
  width: 50%;
  text-align: center;
}
.blur__block {
  width: 1170px;
  height: 100%;
  left: 375px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.btn {
  text-align: left;
  .el-button {
    width: 250px;
    height: 62px;
    font-family: "Inter-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    background: #2e4d4f;
    &:hover {
      background: #f78989;
      transition-duration: 1s;
    }
  }
}
.fullscreen-bg {
  overflow: hidden;
  //   z-index: -100;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 45%;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.overlay {
  background: url("../../assets/main/mainBg.jpg");
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content h2 {
  text-align: center;
  font-size: 30px;
}

.content p {
  text-align: justify;
  font-size: 20px;
}

@media (max-width: 767px) {
  .fullscreen-bg {
    // background: url("../images/plane.jpg") center center / cover no-repeat;
  }

  .fullscreen-bg__video {
    display: none;
  }

  .overlay h1 {
    font-size: 40px;
  }
}

/**/
.backToSitehereBtn {
  display: block;
  width: 150px;
  height: 150px;
  background: #01bc78;
  text-align: center;
  line-height: 170px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  text-decoration: none;
  top: -40px;
  left: -40px;
  transform: rotate(-45deg) scale(1);
  transition: all 0.25s linear;
}

.backToSitehereBtn:hover {
  text-decoration: none;
  top: -10px;
  left: -10px;
  transform: rotate(-45deg) scale(1.3);
}
</style>
