<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <el-backtop :bottom="128">
      <div class="back-top-button">
        <i class="el-icon-arrow-up" />
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}
ul, li, p, h1, h2, h3 {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.el-input__inner {
    height: 52px!important;
}
@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("./assets/fonts/Roboto-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("./assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: url("./assets/fonts/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("./assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("./assets/fonts/Roboto-ThinItalic.ttf") format("truetype");
}

</style>
