<template>
  <footer>
    <div class="footer__wrapper">
      <div class="footer__content">
        <div class="block">
          <p class="text title">Nous rendre visite</p>
          <p class="text">
            Le Cabinet est situé en face du Musée des Comores et du Palais de
            justice de Moroni.
          </p>
        </div>
        <div class="block">
          <p class="text title">Nous localiser</p>
          <div class="block-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7106.929789126152!2d81.06637262465634!3d27.047069882279413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sBoulevard%20Karthala%2C%20Darsanda%2C%20Immeuble%20Ridjali%2C%201%C3%A8re%20Etage!5e0!3m2!1sru!2sby!4v1615464940629!5m2!1sru!2sby"
              width="322"
              height="204"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div class="block">
          <p class="text title">Nous contacter</p>
          <p class="text">
            Boulevard Karthala, Darsanda, Immeuble Ridjali, 1ère Etage
          </p>
          <div class="block-contact">
            <!-- <p class="text">
              <img src="../assets/footer/phone.svg" alt="" />
              +269 - 346 - 3767
            </p>
            <p class="text">
              <img src="../assets/footer/phone2.svg" alt="" />
              +269 - 773 - 1496
            </p> -->
            <p class="text">
              <img src="../assets/footer/mail.svg" alt="" />
              support@avocatetavocat.org
            </p>
            <p class="text">
              <img src="../assets/footer/map.svg" alt="" />
              See Map
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer__wrapper {
  max-width: 1920px;
  width: 100%;
}
footer {
  background: #000000;
}
.footer__content {
  display: flex;
  justify-content: center;
  padding-top: 65px;
  padding-bottom: 79px;
}
.text {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.title {
  text-align: center;
  margin-bottom: 29px;
}
.block {
  width: 30%;
}
.block-contact {
  margin-top: 30px;
  img {
      padding-right: 10px;
  }
}
.block-map {
  text-align: center;
}
</style>
