<template>
  <div class="main-wrapper">
    <Main />

    <div class="main-section">
      <p class="text-bold">
        Le Cabinet Agerius est le premier Cabinet comorien qui offre des
        prestations complètes aussi bien en conseil qu’en contentieux.
      </p>
      <p class="text padding-bottom-40 ">Son expertise couvre plusieurs domaines:</p>
      <ul class="padding-bottom-40 ">
        <li class="text">- Droit des affaires</li>
        <li class="text">- Droit social</li>
        <li class="text">- Droit fiscal</li>
        <li class="text">- Droit administratif</li>
        <li class="text">
          - Le Droit de la propriété intellectuelle et le droit de la
          concurrence
        </li>
        <li class="text">- Consulting</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Main from "./main.vue";

export default {
  components: {
    Main,
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 33px;
}
.text {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
}
.text-bold {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  padding-bottom: 30px;
}
.padding-bottom-40 {
    padding-bottom: 40px;
}
</style>
