<template>
  <div class="main-wrapper">
    <LittleMain />
    <div class="main-section">
      <div class="padding-bottom-40">
        <p class="text padding-bottom-40">
          Le Cabinet fournit ses prestations dans les domaines suivants:
        </p>
        <div class="block-menu margin-bottom-70">
          <div @click="$router.push('/Consultation')" class="list margin-right-60">
            <p class="text">
              La Consultation
            </p>
          </div>
          <div @click="$router.push('/CitizenshipLaw')" class="list margin-right-60">
            <p class="text">Le Droit de la Nationalité</p>
          </div>
          <div @click="$router.push('/IctLaw')" class="list">
            <p class="text">Le Droit des NTIC</p>
          </div>
        </div>
        <div class="block-menu margin-bottom-70">
          <div @click="$router.push('/CompetitionLaw')" class="list margin-right-60">
            <p class="text">
              Le droit de la concurrence et de la propriété intellectuelle
            </p>
          </div>
          <div @click="$router.push('/PetroleumLaw')" class="list margin-right-60">
            <p class="text">Le Droit pétrolier</p>
          </div>
          <div @click="$router.push('/Transportlaw')" class="list">
            <p class="text">Le droit des transports</p>
          </div>
        </div>
        <div class="block-menu margin-bottom-70">
          <div @click="$router.push('/Administrativelaw')" class="list margin-right-60">
            <p class="text">Le Droit administratif</p>
          </div>
          <div @click="$router.push('/Civillaw')" class="list margin-right-60">
            <p class="text">Le Droit civil</p>
          </div>
          <div @click="$router.push('/Taxlaw')" class="list">
            <p class="text">Le Droit fiscal</p>
          </div>
        </div>
        <div class="block-menu">
          <div @click="$router.push('/SocialLaw')" class="list margin-right-60">
            <p class="text">Le Droit social</p>
          </div>
          <div @click="$router.push('/CompaniesLaw')" class="list margin-right-60">
            <p class="text">Le droit des Sociétés</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LittleMain from "./littleMain.vue";

export default {
  components: {
    LittleMain,
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  max-width: 1170px;
  margin: 0 auto;
}
.text {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
  text-align: center;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.block-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list {
  height: 200px;
  width: 100%;
  background: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
