<template>
  <div class="main-wrapper">
    <MapMain />
    <div class="main-section">
      <div class="block-global">
        <div>
          <div class="form">
            <p class="title">
              <img src="../../assets/contacts/pen.svg" alt="" />
              Formulaire de contact
            </p>
            <div class="inputs">
              <el-input
                placeholder="Full name*"
                class="input input__left"
                v-model="firstName"
              ></el-input>
              <el-input
                placeholder="Email*"
                class="input"
                v-model="email"
              ></el-input>
            </div>
            <div class="inputs long">
              <el-input
                placeholder="Subject*"
                class="input"
                v-model="subject"
              ></el-input>
            </div>
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Message*"
              class="input textarea"
              v-model="message"
            ></el-input>

            <el-button
              type="primary"
              @click="sendMessage"
              :loading="sendBtnLoading"
              :plain="true"
              >SUBMIT</el-button
            >
          </div>
        </div>
        <div>
          <p class="title">
            <img src="../../assets/contacts/map.svg" alt="" />
            Addresse
          </p>
          <p class="text-bold">
            Boulevard Karthala, Darsanda, Immeuble Ridjali, 1ère Etage
          </p>
          <!-- <p class="text">
            <img src="../../assets/contacts/phone.svg" alt="" />
            Mob: +269 – 346 – 3767
          </p>
          <p class="text">
            <img src="../../assets/contacts/fax.svg" alt="" />
            Fixe: +269 – 346 – 3767
          </p> -->
          <p class="text">
            <img src="../../assets/contacts/mail.svg" alt="" />
            Email: support@avocatetavocat.org
          </p>
          <p class="text-bold">
            <img src="../../assets/contacts/clock.svg" alt="" />
            Heures d’ouvertures
          </p>
          <p class="text">Du Lundi au Jeudi: 8:00 à 17H00</p>
          <p class="text">Vendredi: 8:00 à 11H00</p>
          <p class="text">Samedi: 8:00 à 12H00</p>
          <p class="text">Dimanche: Fermé</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapMain from "./mapsMain.vue";

export default {
  components: {
    MapMain,
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  max-width: 1170px;
  margin: 0 auto;
}
.title {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 32px;
}
.text {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}
.text-bold {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 22px;
  margin-top: 22px;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.block-global {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 87px;
  margin-bottom: 73px;
}
.el-input {
  margin-bottom: 32px;
}
.el-textarea {
  margin-bottom: 32px;
}
</style>
