<template>
  <div>
    <div class="baner">
      <p class="text-baner">PRÉSENTATION DU CABINET</p>
    </div>
    <div class="fullscreen-bg">
      <div class="overlay">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7106.929789126152!2d81.06637262465634!3d27.047069882279413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sBoulevard%20Karthala%2C%20Darsanda%2C%20Immeuble%20Ridjali%2C%201%C3%A8re%20Etage!5e0!3m2!1sru!2sby!4v1615464940629!5m2!1sru!2sby"
          width="1168"
          height="477"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>


.overlay {
 text-align: center;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content h2 {
  text-align: center;
  font-size: 30px;
}

.content p {
  text-align: justify;
  font-size: 20px;
}

@media (max-width: 767px) {
  .fullscreen-bg {
    // background: url("../images/plane.jpg") center center / cover no-repeat;
  }

  .fullscreen-bg__video {
    display: none;
  }

  .overlay h1 {
    font-size: 40px;
  }
}
.text-baner {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #ffffff;
}
.baner {
  margin-top: 92px;
  margin-bottom: 70px;
  width: 100%;
  height: 102px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
